import { useQuery } from "@apollo/client";
import {
    PanuiQueryData,
    GET_PANUI,
    showLoad,
    getPanuiSlug,
} from "@plinknz/tah-website-elements";
import { format, formatISO } from "date-fns";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { Content } from "../components/content-blocks/content";
import { Hero } from "../components/hero";
import { Loader } from "../components/loader";
import { PageTitle } from "../components/page-title";
import { Page } from "./page";

export const Panui = () => {
    const location = useLocation();
    const slug = getPanuiSlug(location);
    const { loading, data, error } = useQuery<PanuiQueryData>(GET_PANUI, {
        variables: { slug },
    });

    if (!data && showLoad(loading)) {
        return (
            <div className="splash">
                <Loader size="large" />
            </div>
        );
    }

    if (!showLoad(loading) && !error && data?.newsItems.length === 0) {
        // Redirect to page in case this is a normal page with a pānui slug
        return <Page />;
    }

    if (error) {
        console.warn(error);
        const errorMessage = error?.graphQLErrors
            .map((graphqlError) =>
                graphqlError.extensions?.exception
                    ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
                      `${graphqlError.extensions.exception.output.statusCode} ${graphqlError.extensions.exception.message}`
                    : null
            )
            .filter(Boolean)
            .join(", ");

        return (
            <Hero
                title="There was an unexpected error"
                subtitle={errorMessage}
            />
        );
    }

    const page = data?.newsItems?.[0];

    if (!page) {
        // Redirect to page in case this is a normal page with a pānui slug
        return <Page />;
    }

    const datePublished = page.published_at
        ? new Date(page.published_at)
        : null;

    return (
        <>
            <PageTitle title={page.headline} />
            <Hero
                title={page.headline}
                image={page.cover_image}
                subtitle={
                    <>
                        {page.author && <> {page.author} &bull; </>}
                        {datePublished && (
                            <time dateTime={formatISO(datePublished)}>
                                {format(datePublished, "dd  MMMM, yyyy")}
                            </time>
                        )}
                    </>
                }
            />
            <article className="page">
                <Content
                    data={{
                        __typename: "ComponentContentBlocksContent",
                        id: page.id,
                        content: page.content,
                    }}
                />
            </article>
        </>
    );
};
