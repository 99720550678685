/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* istanbul ignore file */
import * as Survey from "survey-react";
import {
    GrantPayload,
    MemberPayload,
} from "../config/back-to-school-grant/payload";

export function transformBackToSchoolGrantData(
    survey: Survey.ReactSurveyModel
): GrantPayload {
    const surveyData = survey.data;
    const data: MemberPayload = {
        parentName: surveyData.parentName,
        phone: surveyData.phone,
        email: surveyData.email,
        address: {
            line1: surveyData.addressfinder.address_line_1 || "",
            line2: surveyData.addressfinder.address_line_2 || "",
            suburb: surveyData.addressfinder.suburb || "",
            city: surveyData.addressfinder.city || "",
            postcode: surveyData.addressfinder.postcode || "",
            country: surveyData.addressfinder.country || "",
        },
        children: [],
    };

    if (surveyData.bankAccountCountry === "Aotearoa") {
        const bankData = surveyData.bank;
        data.bankAccount = {
            accountName: bankData.name,
            accountNumber: `${bankData.bank}-${bankData.branch}-${bankData.account}-${bankData.suffix}`,
        };
    } else {
        data.overseasBankAccount = {
            country: surveyData.overseasBankCountry,
            swiftCode: surveyData.overseasBankSwift,
            accountName: surveyData.overseasBankName,
            accountNumber: surveyData.overseasBankNumber,
            accountAddress: surveyData.overseasBankAddress,
        };
    }

    let i = 1;
    /* eslint-disable no-prototype-builtins */
    while (surveyData.hasOwnProperty(`child${i}Name`)) {
        data.children.push({
            childName: surveyData[`child${i}Name`],
            childDOB: surveyData[`child${i}DOB`],
            childID: surveyData[`child${i}ID`],
            childSchool: surveyData[`child${i}School`],
            childSchoolYear: surveyData[`child${i}SchoolYear`],
        });
        i += 1;
    }

    return { data, recaptcha: null, tenant: "KUIA" };
}
